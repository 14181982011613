<template>
    <div>
      <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center vertical-align-middle">#</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.sku') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.pos') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.barcode') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.goods') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.unit') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.goods_description') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.size') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.quantity') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.note') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.goods_expired_date') }}</th>
              <th role="columnheader" class="text-center vertical-align-middle">{{ $t('labels.supplier') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in items" :key="`${item.id}_${key}`" class="text-center">
              <td>{{ key + 1 }}</td>
              <td :class="{ 'error--text font-italic': !item.sku }">{{ item.sku || 'Hàng hóa mới' }}</td>
              <td>{{ item.pos_code }}</td>
              <td>
                <b v-if="!item.customer_goods_barcode" class="error--text">-----</b>
                <span v-else>{{ item.customer_goods_barcode }}</span>
              </td>
              <td>
                <b v-if="!item.name" class="error--text">-----</b>
                <span v-else>{{ item.name }}</span>
              </td>
              <td>
                <b v-if="!item.unit" class="error--text">-----</b>
                <span v-else>{{ item.unit }}</span>
              </td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
              <td>
                <b v-if="item.sku && (!item.quantity || item.quantity == '0')" class="error--text">-----</b>
                <span v-else>{{ item.quantity }}</span>
              </td>
              <td>{{ item.note }}</td>
              <td>{{ item.process_exp_date ? 'Có' : '' }}</td>
              <td>
                {{ item.supplier }}
                <!-- <div v-html="item.secondary_label_note" class="text-left" style="white-space: pre"></div> -->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
  
      <v-row class="pt-3">
        <v-col cols="12" md="8">
          <div class="d-flex justify-start align-center">
            <a class="mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default default"
              :href="templateLink" target="_blank">
              <v-icon>mdi-download</v-icon>
              {{ $t('labels.sample_file') }}
            </a>
            <v-btn color="info" class="mr-2" @click="$refs.inputUploadFile.click()">
                <v-icon>mdi-upload</v-icon> {{ $t('labels.upload') }}
            </v-btn>
            <input type="file" ref="inputUploadFile" accept=".xlsx" @change="onInputFileChange" class="d-none">
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="d-flex justify-end">
            <v-btn color="success" @click="goodsReceiptSubmit" :disabled="isDisabledBtnSubmit">
                <v-icon>mdi-check</v-icon>
                {{ $t('labels.goods_receipt_create') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
  
  
    </div>
  </template>
  
  <script>
  import { read, utils } from 'xlsx';
  import { httpClient } from "@/libs/http";
  export default {
    name: "ReceiptCreate",
    data: () => ({
      isLoading: false,
      items: [],
      page: 1,
      totalPage: 1
    }),
    computed: {
      templateLink() {
        return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_nhap_pos.xlsx`
      },
      isDisabledBtnSubmit() {
        if (this.items.length === 0) {
          return true
        }
        const checkItems = [...this.items].filter(item => !item.customer_goods_barcode || !item.name || !item.unit || (item.sku && (!item.quantity || item.quantity == '0')))
        return checkItems.length > 0
      },
      itemGroupPos() {
        const group = {}
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i]
          if (!group[item.pos_code]) {
            group[item.pos_code] = []
          }
          group[item.pos_code].push(item)
        }
        return group
      }
    },
    watch: {
  
    },
    methods: {
      downloadExcelExample() { },
      async onInputFileChange(e) {
        const mappingFields = {
          'Barcode': 'customer_goods_barcode',
          'Hàng hóa': 'name',
          'Cửa hàng': 'pos_code',
          'QL. Hạn sử dụng': 'process_exp_date',
          'Size': 'size',
          'Số lượng': 'quantity',
          'Đơn vị': 'unit',
          'Đặc tả hàng hóa': 'description',
          'Ghi chú': 'note',
          'Nhà cung cấp': 'supplier',
        }
        const files = e.target.files, f = files[0]
        const reader = new FileReader()
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result)
          const workbook = read(data, { type: 'array' })
          let sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const values = utils.sheet_to_json(worksheet)
          const items = [...values].map(v => {
            const item = {}
            Object.keys(v).forEach(i => {
              item[mappingFields[i]] = v[i]
            })
            return item
          })
          await this.syncGoodsData(items)
        }
        await reader.readAsArrayBuffer(f)
      },

      async syncGoodsData(items) {
        if (!items || items.length === 0) {
          this.$vToastify.error(this.$t('messages.file_is_empty'))
        }
        if (items.length > 1000) {
          this.$vToastify.error(this.$t('messages.create_receipt_max_1000_rows'))
        }
        const duplicateBarcodes = [];
        const noDuplicateBarcodes = [];
        items.forEach(i => {
          const nd = [...noDuplicateBarcodes].find(n => n === `${i.pos_code}: ${i.customer_goods_barcode}`)
          if (!nd) {
            noDuplicateBarcodes.push(`${i.pos_code}: ${i.customer_goods_barcode}`)
          } else {
            duplicateBarcodes.push(`${i.pos_code}: ${i.customer_goods_barcode}`)
          }
        })
        if (duplicateBarcodes.length > 0) {
          this.$vToastify.error(this.$t('messages.duplicate_barcodes', { barcodes: duplicateBarcodes.join('; ') }));
          return false
        }
  
        const barcodes = [...items].map(i => (i.customer_goods_barcode))
        const { data } = await httpClient.post('/get-goods-by-barcodes', { barcodes })
        this.items = [...items].map(i => {
          const item = [...data].find(d => d.customer_goods_barcode == i.customer_goods_barcode)
          if (item) {
            return { ...i, ...item }
          }
          return i
        })
      },

      async goodsReceiptSubmit() {
        if (this.isLoading) {
          this.$vToastify.warning(this.$t('messages.loading'))
          return false
        }
        this.isLoading = true
  
        try {
          await httpClient.post('/pos-create-receipt-direct', {
            groups: this.itemGroupPos
          })
          this.items = []
          this.$vToastify.success(this.$t('messages.create_success'))
          this.isLoading = false
        } catch (e) {
          this.isLoading = false
          const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
          this.$vToastify.error(errMsg)
        }
      }
    }
  }
  </script>
  
  <style scoped></style>
  